import React, { useState } from 'react'
import {
  Stack,
  Heading,
  Input,
  Button,
  Text,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { Auth } from 'aws-amplify'
import { navigate } from '@reach/router'
import { request } from '../../utils/request'
// import { useMachine } from '@xstate/react'
// import confirmationMachine, { events } from './confirmation.machine'
import LS from '../../utils/local-storage'

const Confirmation = () => {
  // const [currentState, send] = useMachine(confirmationMachine)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const signupInfo = LS.getItem('app:user')

  const handleConfirmation = async ({ email, confirmationCode }) => {
    try {
      if (error) {
        setError(null)
      }

      await Auth.confirmSignUp(email, confirmationCode)

      if (signupInfo && signupInfo.password) {
        await Auth.signIn(email, signupInfo.password)

        LS.removeItem('app:user')

        await request({
          method: 'post',
          url: '/v1/user',
          data: {
            email,
          },
        })

        navigate('/dashboard/getting-started')
      } else {
        navigate('/login')
      }
    } catch (err) {
      setError(
        err?.message || 'Please contact support if this problem persists'
      )
    }
  }

  const formik = useFormik({
    initialValues: {
      email: signupInfo?.email || '',
      confirmationCode: '',
    },
    onSubmit: async values => {
      const { email, confirmationCode } = values
      setIsLoading(true)
      await handleConfirmation({ email, confirmationCode })
      setIsLoading(false)
      // send({
      //   type: events.SUBMIT,
      //   payload: {
      //     email: values.email,
      //     confirmationCode: values.confirmationCode,
      //   },
      // })
    },
  })

  // const isLoading = currentState.matches('fetching')

  return (
    <Stack maxWidth="md" spacing={5} width="100%">
      <Heading textAlign="center">Confirmation</Heading>
      <Text fontSize="lg" pt="3" textAlign="center">
        Please check your email for your confirmation code
      </Text>
      <Stack borderWidth="1px" mt={5} px={5} py={3} rounded="md">
        <FormControl
          as="form"
          isInvalid={Boolean(error)}
          my={6}
          onSubmit={formik.handleSubmit}
        >
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            mb={4}
            onChange={formik.handleChange}
            size="lg"
            type="email"
            value={formik.values.email}
          />

          <FormLabel htmlFor="email">Confirmation Code</FormLabel>
          <Input
            id="confirmationCode"
            onChange={formik.handleChange}
            size="lg"
            type="text"
            value={formik.values.confirmationCode}
          />

          <FormErrorMessage>{error}</FormErrorMessage>
          <Flex justifyContent="flex-end">
            <Button mt={5} type="submit" colorScheme="teal" width="100%">
              {isLoading ? <Spinner /> : 'Submit'}
            </Button>
          </Flex>
        </FormControl>
      </Stack>
    </Stack>
  )
}

export default Confirmation
